import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { Link, useLocation } from "react-router-dom";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { connectors } from "../utils/web3React";

const Header: React.FC = () => {
  const { account, activate } = useWeb3React();
  const [scroll, setScroll] = useState(false);
  const location = useLocation();

  useEffect(() => {
    activate(connectors.injected);

    const handleScroll = () => {
      setScroll(window.scrollY > 300);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [account, activate]);

  return (
    <header
      className={`header ${scroll ? "is-fixed" : ""}`}
      style={{
        // width: "100%",
        backgroundColor: "#1a1a40",
        color: "white",
        padding: "1px 20px",
        boxShadow: "0px 4px 2px -2px gray",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        overflow: "hidden",
      }}
    >
      <div
        className="tf-container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          overflowX: "hidden", // Ensure no overflow inside container
        }}
      >
        <div
          id="site-header-inner"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            className="header-left"
            style={{
              flex: "1",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src="/images/Asset 19@2x-8.png"
              alt="iLendCapital"
              style={{
                height: "80px", // Adjusted height for better fit
                maxWidth: "100%", // Prevents overflow
                objectFit: "contain", // Maintain aspect ratio
              }}
            />
          </div>
          <div
            className="header-center"
            style={{
              flex: "2",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <nav
              style={{
                display: "flex",
              }}
            >
              <ul
                style={{
                  listStyleType: "none",
                  padding: 0,
                  margin: 0,
                  display: "flex",
                  flexWrap: "wrap", // Wrap items if necessary
                }}
              >
                <li
                  style={{
                    marginLeft: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Link
                    to="/"
                    style={{
                      color: location.pathname === "/" ? "#f1c40f" : "white",
                      textDecoration: "none",
                      fontSize: "18px",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      transition: "color 0.3s ease",
                    }}
                  >
                    Dashboard
                  </Link>
                </li>
                <li
                  style={{
                    marginLeft: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Link
                    to="/vault"
                    style={{
                      color:
                        location.pathname === "/vault" ? "#f1c40f" : "white",
                      textDecoration: "none",
                      fontSize: "18px",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      transition: "color 0.3s ease",
                    }}
                  >
                    Vault
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div
            className="header-right"
            style={{
              flex: "1",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <ConnectButton accountStatus="full" showBalance={true} />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
