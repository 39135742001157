import { InjectedConnector } from "@web3-react/injected-connector";
//import { ethers } from "ethers";
const ethers = require("ethers");

const injected = new InjectedConnector({
  supportedChainIds: [56],
});
export const simpleRpcProvider = new ethers.providers.JsonRpcProvider(
  "https://binance.llamarpc.com"
);

export const connectors = {
  injected,
};

export const getLibrary = (provider) => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = 3000; // frequency provider is polling
  return library;
};

/*
export const signMessage = async (connector, provider, account, message) => {
  return provider.getSigner(account).signMessage(message);
};

*/
