import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import abis from "../constants/abi";
import contracts from "../constants/contracts";
import getRpcUrl from "../utils/getRpcUrl";
import useToast from "../hooks/useToast";

const Dashboard = () => {
  const { account, active } = useWeb3React();
  const { toastSuccess, toastError } = useToast();
  const [amountToMint, setAmountToMint] = useState(1); // Number of NFTs to mint
  const [pricePerNFT, setPricePerNFT] = useState(100); // Price in USDT per NFT, default is 100
  const [totalPrice, setTotalPrice] = useState(0); // Total price calculated
  const [totalMint, setTotalMint] = useState(0); // Total number of NFTs minted
  const [nftsOwnedByUser, setNftsOwnedByUser] = useState(0); // NFTs owned by the user
  const [referral, setReferral] = useState(""); // Referral address
  const [refresh, setrefresh] = useState(false); // For refreshing the page
  const [totalPaidByAll, settotalPaidByAll] = useState(0); // Total USDT paid by all users

  const rpcUrl = getRpcUrl();
  const web3 = new Web3(Web3.givenProvider || rpcUrl);
  const contract = new web3.eth.Contract(abis.NFT, contracts.NFT);
  const usdtContract = new web3.eth.Contract(abis.USDT, contracts.USDT);

  useEffect(() => {
    const fetchData = async () => {
      console.log(account);
      if (!account) return;
      try {
        const totalMinted = await contract.methods.totalSupply().call();
        const userNFTs = await contract.methods.balanceOf(account).call();
        const totalPaidByAllis = await contract.methods.totalPaidByAll().call();
        setTotalMint(totalMinted);
        setNftsOwnedByUser(userNFTs);
        settotalPaidByAll(totalPaidByAllis);
      } catch (error) {
        console.error("Error fetching data from contract:", error);
        toastError("Failed to fetch contract data.");
      }
    };

    fetchData();
  }, [account, refresh]);

  useEffect(() => {
    const updateTotalPrice = () => {
      const total = parseFloat(pricePerNFT) * amountToMint;
      setTotalPrice(total.toFixed(2));
    };

    updateTotalPrice();
  }, [amountToMint, pricePerNFT]);

  const handleMint = async () => {
    try {
      if (!account || !active) {
        toastError("Please connect your wallet first.");
        return;
      }

      const amountInWei = web3.utils.toWei(totalPrice.toString(), "ether");

      // Check if referral address is provided
      const referralAddress = referral.trim() !== "" ? referral : "0x0000000000000000000000000000000000000000";

      var allowance = await usdtContract.methods.allowance(account, contracts.NFT).call({ from: account });
      // if (Number(allowance) < Number(amountInWei)) {
      await usdtContract.methods.approve(contracts.NFT, amountInWei).send({ from: account });
      // }
      await contract.methods.safeMint(amountToMint, web3.utils.toWei(pricePerNFT.toString(), "ether"), referralAddress).send({ from: account });
      setrefresh(!refresh);
      toastSuccess("Mint Successful!");
    } catch (error) {
      console.error("Minting error:", error);
      toastError("Mint Failed! Error: " + error.message);
    }
  };

  return (
    <div>
      <div style={tableStyle}>
        <div style={rowStyle}>
          <div style={cellStyle}>Total NFTs Minted</div>
          <div style={cellStyle}>Total Supply</div>
          <div style={cellStyle}>NFT in Wallet</div>
          <div style={cellStyle}>DCER APR/APY</div>
        </div>
        <div style={rowStyle}>
          <div style={cellValueStyle}>{totalMint}</div>
          <div style={cellValueStyle}>{totalPaidByAll / 10 ** 18} USDT</div>
          <div style={cellValueStyle}>{nftsOwnedByUser}</div>
          <div style={cellValueStyle}>{120}%</div>
        </div>
      </div>
      <div style={infoContainerStyle}>
        <p>We do not offer compounding as this is intended to be a safe investment opportunity.</p>
        <p>We advise all clients not to invest what they cannot afford to lose as this is high risk, but it is still a safer investment option.</p>
        <p>
          We advise clients to take dividends as soon as they are made available. This is DeFi, and this space is prone to malicious and bad actors.
        </p>
        <p>Please do not share your wallet info with anyone. No one from our team will ask for such info.</p>
        <p>
          This is not a honeypot; it is not first come, first serve. Users intending to access iLend products or services are not allowed to enter
          with harmful or malicious intent. Actions of violating our services will lead to immediate dismissal and future barring.
        </p>
        <p>We are not here to only make money, but we are here to provide safety and reliability for our users and investors alike.</p>
      </div>
      <div style={stepsWithLogoStyle}>
        <div style={stepsContainerStyle}>
          <ul style={stepsListStyle}>
            <h3 style={stepsTitleStyle}>Steps to Mint</h3>
            <li style={stepItemStyle}>Step 1: Select Binance Chain</li>
            <li style={stepItemStyle}>Step 2: We only accept USDT on the Binance Network</li>
            <li style={stepItemStyle}>Step 3: Connect Wallet</li>
            <li style={stepItemStyle}>Step 4: Scroll down for minting</li>
            <li style={stepItemStyle}>Step 5: Enter the amount you wish to Lend (NFT Mint Minimum is $100)</li>
            <li style={stepItemStyle}>Step 6: Hit the mint button, confirm approval info, then go ahead and confirm</li>
            <li style={stepItemStyle}>Step 7: After Minting is complete, confirm NFT shows Minted</li>
            <li style={stepItemStyle}>Step 8: Add your newly minted NFT into collateralization by going to the Vault section (link above)</li>
            <li style={stepItemStyle}>Step 9: Vault your NFT by selecting your NFT under Stake an NFT, select your NFT, and then click Vault NFT</li>
            <li style={stepItemStyle}>Step 10: Check back in the Vault in 3 days! (dividends start generating 2 days after vault)</li>
            <li style={stepItemStyle}>Step 11: Go to the Vault and hit Take Profit daily!!</li>
            <li style={stepItemStyle}>Step 12: Rinse, Wash, Repeat, Vault, Take Profit!</li>
          </ul>
        </div>

        <div className="mint-box">
          <img src="/images/iLend NFT@2x-8.png" alt="Logo" style={logoStyle} />
        </div>
      </div>
      <div style={tableStyle}>
        <div style={mintContainerStyle}>
          <h3 style={mintLabelStyle}>Amount to Mint</h3>
          <input style={mintValueStyle} type="number" value={amountToMint} onChange={(e) => setAmountToMint(e.target.value)} min="1" />
          <h3 style={mintLabelStyle}>Price per NFT (in USDT)</h3>
          <input style={mintValueStyle} type="number" value={pricePerNFT} onChange={(e) => setPricePerNFT(e.target.value)} min="100" max="3000000" />
          <h3 style={mintLabelStyle}>
            Referral Address <br />
            (you cannot put your wallet as Referral)
          </h3>
          <input
            style={mintValueStyle}
            type="text"
            placeholder="Enter Referral Address"
            value={referral}
            onChange={(e) => setReferral(e.target.value)}
          />
          <div style={mintValueStyle}>Total Price to Mint: {totalPrice} USDT</div>
          <button style={mintButtonStyle} onClick={handleMint}>
            Mint
          </button>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

// Responsive style definitions...

const tableStyle = {
  width: "100%",
  marginBottom: "20px",
  backgroundColor: "#2c2c3a",
  borderRadius: "12px",
  paddingBottom: "20px",
  paddingTop: "20px",
  display: "flex",
  flexDirection: "column", // Column layout by default
};

const rowStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginBottom: "10px",
  flexWrap: "wrap", // Allow wrapping for smaller screens
};

const cellStyle = {
  flex: 1,
  fontWeight: "bold",
  fontSize: "16px",
  textAlign: "center",
  color: "#b0b0b0",
  marginBottom: "10px",
};

const cellValueStyle = {
  flex: 1,
  fontSize: "18px",
  textAlign: "center",
  color: "white",
  marginBottom: "10px",
};

// Add a media query to adjust for mobile screens

const stepsWithLogoStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap", // Enable wrapping on small screens
  marginTop: "20px",
};

const stepsContainerStyle = {
  backgroundColor: "#2c2c3a",
  padding: "20px",
  borderRadius: "12px",
  flex: 1,
  marginRight: "20px",
  marginBottom: "20px",
  boxSizing: "border-box",
};

const stepsTitleStyle = {
  fontSize: "18px",
  fontWeight: "bold",
  color: "#f1c40f",
  marginBottom: "10px",
  textAlign: "center",
};

const stepsListStyle = {
  listStyleType: "none",
  padding: "0",
};

const stepItemStyle = {
  fontSize: "16px",
  color: "#b0b0b0",
  marginBottom: "8px",
};

const logoStyle = {
  height: "auto",
  width: "100%",
  maxWidth: "350px",
  padding: "20px",
  margin: "0 auto", // Center on smaller screens
};

const mintContainerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "20px",
  padding: "20px",
  backgroundColor: "#2c2c3a",
  borderRadius: "12px",
  textAlign: "center",
  boxSizing: "border-box",
};

const mintLabelStyle = {
  fontSize: "18px",
  fontWeight: "bold",
  color: "#b0b0b0",
  marginBottom: "10px",
};

const mintValueStyle = {
  fontSize: "24px",
  fontWeight: "bold",
  color: "white",
  marginBottom: "10px",
  padding: "5px 15px",
  backgroundColor: "#1f1f2e",
  borderRadius: "5px",
  width: "100%", // Ensure input fields take full width on smaller screens
  maxWidth: "300px",
};

const mintButtonStyle = {
  backgroundColor: "#8e44ad",
  color: "white",
  padding: "10px 20px",
  border: "1px solid #f1c40f",
  borderRadius: "5px",
  cursor: "pointer",
  fontWeight: "bold",
  width: "100%",
  maxWidth: "300px",
};

const infoContainerStyle = {
  backgroundColor: "#1f1f2e",
  color: "white",
  padding: "20px",
  borderRadius: "10px",
  fontFamily: "Arial, sans-serif",
  lineHeight: "1.6",
  margin: "20px 0",
  boxSizing: "border-box",
};
